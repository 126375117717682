(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"));
	else if(typeof define === 'function' && define.amd)
		define("outsiderTaskManage", ["React"], factory);
	else if(typeof exports === 'object')
		exports["outsiderTaskManage"] = factory(require("React"));
	else
		root["outsiderTaskManage"] = factory(root["React"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__) {
return 