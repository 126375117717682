import React from 'react'

const MonitorTaskAddContainer = Loader.loadModuleComponent('MonitorTasks', 'MonitorTaskAddContainer')

const OutSiderMonitorTaskAdd = () => {
  const sceneCode = BaseStore.user.appInfo.sceneCode;
  const monitorLabel = Dict.map.monitorLabel[sceneCode];
  return (
    <MonitorTaskAddContainer 
      libType={2}
      libLabel={monitorLabel.outsider.libPeople}
      taskLabel={monitorLabel.outsider.label}
      taskType='101502'
      alarmThreshold={80}
      taskListModuleName='outsiderTaskView'
    />
  )
}
 
export default OutSiderMonitorTaskAdd;

